
<template>
  <div class="AddItem">
<!--     <div class="Notification">
      🎉 Added
    </div> -->
    <div class="header">
    <div class="title">Add Food</div>



 <div class="search-input">
  <input type="text" placeholder="Search" v-model="edamamAPI.query"  @blur="edamamAPISearch()" @click="$panes['add'].moveToHeight(705)">
  <button class="btn search-button" @click="edamamAPISearch()">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
      <circle cx="11" cy="11" r="8" />
      <path d="M21 21l-4.35-4.35" />
    </svg>
  </button>
</div>

  <!--   <div class="search-input">
          <input type="text" placeholder="Search" v-model="edamamAPI.query" @blur="edamamAPISearch()">
          <a class="btn" @click="edamamAPISearch()"><span class="material-symbols-rounded">
search
</span></a>
        </div> -->
      </div>
<!-- 
    <div class="options">
      <div id="marker"></div>
      <div class="option" v-for="name, i in ['Search', 'Add']" @click="current = i" :class="{ current: i == current }" :key="i">{{ name
      }}
      </div>
    </div> -->

<div class="addItemcontainer">
    <div v-if="edamamAPI.query" class="add-content">
      <div class="apiSearch">


        <transition name="fade">
          <div class="edmam" v-if="edamamAPI.result">

            <FoodCard v-for="item in edamamAPI.result" :key="item.id+'main'" :food="item" :type="'add'" class="main">
            </FoodCard>
            
            <div class="more-title">More</div>
            <div class="more">
              <FoodCard v-for="item in edamamAPI.more" :key="item.id" :food="item" :type="'add'">
            </FoodCard>

            </div>


<!-- 
            <li v-for="item in edamamAPI.result" :key="item.id" class="card">


              <img class="card-img" :src="item.image" />
              <div class="text">
              <h3 class="card-name">{{ item.name }}</h3>
              <p class="card-calories">{{ item.caloriesPer100g }} calories</p>
            </div>
              <div class="card-buttons">
                <button @click="addFood(item)" class="card-btn"><span class="material-symbols-rounded">
            add
          </span></button>
              </div>

            </li> -->
          </div>


        </transition>
      </div>
    </div>


<template v-else> 
  <transition name="fade">

  <div class="add-content addCustomEntry" >
      <div class="addImage">
        <img class="image" v-if="newFood.image" :src="newFood.image" width="200" />

        <div v-else class='file-input button'>
          <input type='file' @change="fileSelected">
          <span class="material-symbols-rounded">image</span>
          <!-- <span class='label' data-js-label>No file selected</span> -->
        </div>
      </div>

      <div class="inputs">
        <input type="text" placeholder="Name" v-model="newFood.name"  @click="$panes['add'].moveToHeight(705)">
        <input type="text" placeholder="Calories" v-model="newFood.caloriesPer100g">
      </div>

      <div class="buttons">
        <div class="button add" @click="addFood()"><span class="material-symbols-rounded">
            add
          </span></div>
      </div>
    </div>
  </transition>

</template>

  
  </div>


  </div>
</template>

<script>
import FoodCard from '../FoodCard.vue';

export default {
  name: 'AddItem',
  mounted() {  },
  data() {
    return {
      current: 0,
      newFood: {
            id: '',
            name: '',
            caloriesPer100g: '',
            nutrients:'',
            image: '',
            category:'',
            measure: '',
            calculatedCals:'' ,
            grams: "",
            knownAs:'',
            brand:''
      },
      edamamAPI: {
        query: null,
        result: null,
        more:null
      },
    }
  },
  props: {

  },
  methods: {
    addFood(apiData) {
      if(apiData){
        this.$store.commit('addFood', apiData)
      }
      else {
        this.newFood.id = Date.now()
        this.$store.commit('addFood', this.newFood)
      }
    
      this.newFood = {
        id: '',
            name: '',
            caloriesPer100g: '',
            nutrients:'',
            image: '',
            category:'',
            measure: '',
            calculatedCals:'' ,
            grams: '',
            knownAs:'',
            brand:''
      }
    },
    edamamAPISearch() {
       let api = this.$store.state.auth.api.edmame;

      let query = `https://api.edamam.com/api/food-database/v2/parser?app_id=${api.id}&app_key=${api.key}&ingr=${encodeURIComponent(this.edamamAPI.query)}&nutrition-type=logging`
      fetch(query)
        .then(response => response.json())
        .then(data => {
          //let item = data.parsed
          console.log(data)
         let more = data.hints.map((item) => ({
            id: item.food.foodId,
            name: item.food.label,
            caloriesPer100g: parseInt(item.food.nutrients.ENERC_KCAL,10),
            nutrients: item.food.nutrients,
            image: item.food.image,
            category: item.food.category,
            measure: item.measure,
            knownAs:item.food.knownAs,
            foodContentsLabel:item.food.foodContentsLabel,
            categoryLabel:item.food.categoryLabel,
            calculatedCals: null,
            brand:item.food.brand,
            grams: ""
          }));
          this.edamamAPI.more = more


          let item = data.parsed.map((item) => ({
            id: item.food.foodId,
            name: item.food.label,
            caloriesPer100g: parseInt(item.food.nutrients.ENERC_KCAL,10),
            nutrients: item.food.nutrients,
            image: item.food.image,
            category: item.food.category,
            measure: item.measure,
            knownAs:item.food.knownAs,
            calculatedCals: null,
            grams: ""
          }));



          this.edamamAPI.result = item
          console.log(this.edamamAPI)
        })
        .catch(error => {
          console.log(error)
        });
    },
    fileSelected(event) {
      const file = event.target.files.item(0);
      const reader = new FileReader();
      reader.addEventListener('load', this.imageLoader);
      reader.readAsDataURL(file);
    },
    imageLoader(event) {
      
      this.newFood.image = event.target.result;
      console.log(this.newFood.image)
    }
  },
  components: {
    FoodCard: () => import("@/components/FoodCard.vue"),
    FoodCard
},
}
</script>

<style lang="scss" scoped>
.more-title {
    font-size: 17px;
    font-weight: 500;
    color: var(--gray-600);
    border-top: 1px solid var(--gray-100);
    padding-top: 6px;
}
.more {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.Notification {
    font-weight: 500;
    background: var(--gray-700);
    padding: 12px;
    color: var(--gray-100);
    box-shadow: 2px 3px 11px #1b1d201c;
    /* border: 2px solid var(--gray-200); */
    border-radius: 12px;
    position: absolute;
    bottom: 8em;
    right: 20px;
    /* width: 120px; */
}
.search-input {
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    border-radius: 16px;
    width: 132px;
    display: flex;
    /* flex-direction: row-reverse; */
    /* margin-left: 0em; */
}
input::Placeholder {
    color: var(--gray-400);
}
.search-input input {
    flex: 1;
    outline: none;
    border: 0;
    background: transparent;
    font-size: 0.9rem;
    color:var(--gray-500);
    width: 100%;
    /* background: red; */
}

.search-input button {
    margin-left: auto;
    background: transparent;
    border-radius: 20px;
    border: 0;
    color: var(--gray-400);
    cursor: pointer;
    outline: none;
    width: 32px;
    height: 32px;
    transition: ease all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addItemcontainer {
  width: -webkit-fill-available;
}
.add {
    background: #373a3c;
    border-radius: 16px;
    padding: 10px;
    color: white;
    font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 200, "opsz" 40 !important;
    display: flex;
    /* height: 64px; */
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
}
.title  {
font-size: 24px;
font-weight: 600;
font-family: Poppins;

width: -webkit-fill-available;
}
.card {
    position: relative;
    display: flex;
    width: -webkit-fill-available;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 10px 30px -5px rgb(0 0 0 / 10%);
    transition: all 0.3s ease-out;
    padding: 12px;
    align-items: center;

    &:active {
      box-shadow: 0px 10px 40px -5px rgb(0 0 0 / 20%);
      transform: translateY(-5px);
    }
    .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}
    img.card-img {
      /* margin: 10px 0; */
      border-radius: inherit;
      height: inherit;
      width: auto;
      object-fit: cover;
      height:4em
    }

    h3.card-name {
      margin: 0;
      padding: 0 10px;
      font-size: 1.05rem;
      font-weight: bold;
      text-align: center;
      color: #3c4054f5;
      ne-height: 1.7;
    }

    p.card-calories {
      margin: 0;
      padding: 0 10px;
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.5);
      color: #A2A3A5;
      text-align: center;
      /* font-family: SfProRounded-light; */
    }

 
  }

.options {
  display: flex;
    gap: 12px;
    //position: fixed;
    align-items: center;
   // background: red;
    width: -webkit-fill-available;
    height: 48px;
    left: 0;
    padding-left: 24px;
    top: 0;
    border-radius: 24px 24px 0 0 ;
}
.AddItem {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 18px;
    min-height: -webkit-fit-content;
    
}
.addCustomEntry {
  //height: -webkit-fill-available;
  display: flex;
  align-items: center;
  gap: 12px;
}

.inputs {
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: 100%;
  input[type="text"] {
    padding: 8px;
    font-family: Poppins;
    border-radius: 8px;
    background: var(--input-bg);
    color: var(--input-text);
    width: -webkit-fill-available;

    border: 1px solid #c2c2c27a;
    /* border: none; */
    /* background: #dddddd24; */
    width: -webkit-fill-available;
    color: #57585ebf;
    width: -webkit-fit-content;
    width: 100%;
  }

  ::placeholder {
    color: #4e4e65c7;
    color: var(--placeholder);
    opacity: 1;
    /* Firefox */

  }


}

.file-input {
  /*   font-size: 12px;
  display: inline-block;
  text-align: left;
  padding: 16px;
  width: auto;
  width: -webkit-fill-available;
  position: relative;
  border-radius: 8px;
  color: rgb(51 51 62 / 75%); */


  >[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  /*   >.button {
    display: inline-block;
    cursor: pointer;
    background: #eee;
    padding: 5px 8px;
    border-radius: 2px;
    
    transition: all 0.3s;
    border-radius: 8px;

  } */

  /*   &:hover>.button {
    background: dodgerblue;
    color: white;
  } */
}

.file-input.button {
  background: #0f0f0f;
  background: var(--file-button-bg);
  color: #e7e7e7;
  color: var(--file-button-text);
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;


  height: 64px;
  width: 64px;

  border-radius: 12px;
  min-width: 64px;
  min-width: 68px;
  height: 68px;
  width: 68px;
}




button.card-btn {
    background: #383b44;
    border: none;
    padding: 6px;
    display: flex;
    border-radius: 8px;
    color: white;
    /* font-size: 13px; */
    font-family: Poppins;
}

.card-buttons {}

span.material-symbols-rounded {
    font-size: 17px;
}

.card-buttons {
    /* height: -webkit-fill-available; */
    /* background: red; */
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 12px;
}



.addImage {
    width: 84px;
}
img.image {
    width: -webkit-fill-available;
}
</style>

